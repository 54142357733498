import React, { memo, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { BiMessageSquareDots } from 'react-icons/bi'
import { Virtuoso } from 'react-virtuoso'

import { Box, Text, Icon, Flex, HStack, Spinner } from '@chakra-ui/react'

import { useJourney } from 'modules/journeys/hooks'
import { useMapStore } from 'modules/map/modules/map-canvas/store'
import { useDivergenceStore } from 'modules/map/store/divergence-store'
import useCommentsStore from 'modules/map/store/use-comments-store'
import { Role } from 'modules/user/constants'
import { useMe } from 'modules/user/hooks'
import { User } from 'modules/user/types'

import {
	QuestionWithComments,
	PointInfos
} from '../../store/use-comments-store'
import { QuickSummaryButton } from '../quick-summary/button'
import { AnonymBar } from '../view-divergence-point/anonym-mode/anonym-bar'
import { BackButtonMobile } from '../view-divergence-point/back-button-mobile'
import { CommentAllButton } from './comment-all/button'
import { CommentCard } from './comment-card'
import { FloatingButton } from './floating-button'
import { IntroductionCard } from './introduction-card'

type Props = {
	point: PointInfos
	questions: QuestionWithComments
	questionOpenedIndex: number
	setIsSideBar: () => void
	firstRender?: boolean
	setRender?: () => void
	journeyId: string
}

export function ChatMobile({
	point,
	questions,
	questionOpenedIndex,
	setIsSideBar,
	firstRender,
	setRender,
	journeyId
}: Props) {
	const selectedPoint = useMapStore(({ state }) => state.selectedPoint)

	const questionNumber = questionOpenedIndex
	const { user } = useMe()
	const hasDeletePrivilege = (commentId: string) =>
		user ? user.id === commentId : false
	const boxRef = useRef<HTMLDivElement | null>(null)
	const textQuestion =
		point.tool.questions.find((e) => e.id === questions.id)?.question || ''
	const { isAnonym } = useDivergenceStore()
	const isLoadingComments = useCommentsStore((state) => state.isLoadingComments)

	const onCollapseLastComment = (height: number) => {
		const boxRefCurrent = boxRef.current
		if (boxRefCurrent) {
			boxRefCurrent.scrollTo({
				left: 0,
				top: boxRefCurrent.scrollHeight - height - 80,
				behavior: 'smooth'
			})
		}
	}

	const bg = isAnonym ? 'gray.200' : 'white'

	return (
		<>
			{isLoadingComments ? (
				<Flex justifyContent='center' alignItems='center' height='100%'>
					<Spinner size='xl' />
				</Flex>
			) : (
				<>
					<Content
						boxRef={boxRef}
						hasDeletePrivilege={hasDeletePrivilege}
						isAnonym={isAnonym}
						journeyId={journeyId}
						onCollapseLastComment={onCollapseLastComment}
						questionNumber={questionNumber}
						questions={questions}
						textQuestion={textQuestion}
						user={user}
						firstRender={firstRender}
						setRender={setRender}
						setIsSideBar={setIsSideBar}
						bg={bg}
						hasIntro={!!point.introduction}
						point={point}
						questionId={questions.id}
					/>
					{questions.id &&
						((point.introduction && questionOpenedIndex !== 0) ||
							!point.introduction) && (
							<FloatingButton
								questionId={questions.id}
								selectedPointId={selectedPoint?.id || ''}
								questionNumber={
									questionNumber !== undefined ? questionNumber + 1 : -1
								}
								textQuestion={
									point.tool.questions.find((e) => e.id === questions.id)
										?.question || ''
								}
								journeyId={journeyId}
								point={point}
							/>
						)}
				</>
			)}
		</>
	)
}

type ContentProps = {
	questions: QuestionWithComments
	questionNumber: number
	textQuestion: string
	isAnonym: boolean
	hasDeletePrivilege: (commentId: string) => boolean
	user?: User
	journeyId: string
	onCollapseLastComment: (height: number) => void
	boxRef: React.MutableRefObject<HTMLDivElement | null>
	firstRender?: boolean
	setRender?: () => void
	setIsSideBar: () => void
	bg: string
	hasIntro?: boolean
	point: PointInfos
	questionId: string
}

const Content = memo((props: ContentProps) => {
	const { t } = useTranslation()
	const {
		setRender,
		bg,
		setIsSideBar,
		questions,
		questionNumber,
		textQuestion,
		isAnonym,
		hasDeletePrivilege,
		user: currUser,
		journeyId,
		onCollapseLastComment,
		boxRef,
		firstRender,
		hasIntro = false,
		point,
		questionId
	} = props
	const isEmpty =
		questions && questions.comments && questions.comments.length > 0
	const isIntroComponent = hasIntro && questionNumber === 0

	const { data: journeyData } = useJourney(journeyId as string, {
		select: (journey) => journey
	})

	const hasPrivileges = !!journeyData?.users
		.filter((user) => user.id === currUser?.id)
		.find(({ project_roles }) => Role(project_roles).can('point.comment_all'))

	if (isEmpty) {
		return (
			<Box w='full' minH='full' h='xl' bg={bg}>
				<Box
					w='full'
					backgroundColor='#fff'
					py='4'
					px='4'
					boxShadow='0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06)'
				>
					<BackButtonMobile
						onClickFunc={() => {
							if (firstRender && setRender) {
								setRender()
							}
							setIsSideBar()
						}}
					/>
					{!isIntroComponent && (
						<Text fontSize='md' fontWeight='600' color='gray.300'>
							{`${t('divergencePoint:question')} ${hasIntro ? questionNumber : questionNumber + 1
								}`}
						</Text>
					)}
					<Text fontSize='sm' fontWeight='600' color='gray.1' mt='2'>
						{isIntroComponent
							? t('startingPoint:view.intro')
							: textQuestion.length > 100
								? `${textQuestion.substr(0, 110)}...`
								: textQuestion}
					</Text>
				</Box>
				{isAnonym && <AnonymBar />}
				<HStack w='full' placeContent='flex-start' px={4} pt={4} pb={3}>
					{journeyData?.ai_enabled && (
						<QuickSummaryButton
							user={currUser}
							pointId={point.id}
							questionId={questionId || ''}
						/>
					)}
					{hasPrivileges && (
						<CommentAllButton
							user={currUser}
							pointId={point.id}
							questionId={questionId || ''}
						/>
					)}
				</HStack>
				<Virtuoso
					data={questions.comments}
					itemContent={(index, comment) => (
						<CommentCard
							key={comment.id + index}
							comments={comment}
							hasDeletePrivilege={hasDeletePrivilege(comment.author.id)}
							userName={currUser?.name || ''}
							username={comment.author.username}
							userId={currUser?.id || ''}
							userLanguage={currUser?.language || 'ptBR'}
							onCollapseLastComment={onCollapseLastComment}
							journeyId={journeyId}
							userAvatarUrl={currUser?.avatar_url}
							hasIntro={hasIntro}
						/>
					)}
					style={{ height: '100%', width: '100%' }}
					useWindowScroll={false}
					increaseViewportBy={200}
					components={{ Footer }}
				/>
			</Box>
		)
	}
	return (
		<Box w='full' h='100%'>
			<Box
				w='full'
				backgroundColor='#fff'
				py='4'
				px='4'
				boxShadow='0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06)'
			>
				<BackButtonMobile
					onClickFunc={() => {
						if (firstRender && setRender) {
							setRender()
						}
						setIsSideBar()
					}}
				/>
				{!isIntroComponent && (
					<Text fontSize='md' fontWeight='600' color='gray.300'>
						{`${t('divergencePoint:question')} ${hasIntro ? questionNumber : questionNumber + 1
							}`}
					</Text>
				)}
				<Text fontSize='sm' fontWeight='600' color='gray.1' mt='2'>
					{isIntroComponent
						? t('startingPoint:view.intro')
						: textQuestion.length > 100
							? `${textQuestion.substr(0, 110)}...`
							: textQuestion}
				</Text>
			</Box>
			{isAnonym && <AnonymBar />}
			{isIntroComponent ? (
				<Box px={4} py={2} overflowY='auto' h='100%' ref={boxRef} pb={200}>
					<IntroductionCard introduction={point.introduction} />
				</Box>
			) : (
				<Flex
					h='lg'
					w='full'
					alignItems='center'
					flexDirection='column'
					justifyContent='center'
				>
					<Icon
						as={BiMessageSquareDots}
						fontSize='4.375rem'
						color='gray.400'
						mb='1rem'
					/>
					<Text fontWeight='600' color='gray.400'>
						{t('divergencePoint:chat:chat_empty_1')}
					</Text>
					<Text fontWeight='600' color='gray.400'>
						{t('divergencePoint:chat:chat_empty_2')}
					</Text>
					<Text fontWeight='600' color='gray.400'>
						{t('divergencePoint:chat:chat_empty_3')}
					</Text>
				</Flex>
			)}
		</Box>
	)
})

function Footer() {
	return <Box pt='18rem'></Box>
}
